<template>
    <div>
        <div class="content">
            <div>
                <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
            </div>
    
            <el-card style="margin-top:15px;width:100%" class="box-card">
                <div class="search">
                    <div class="item">
                        <label>姓名</label>
                        <el-input v-model="searchForm.emp_name" class="marg" style="width:150px"></el-input>
                    </div>
                    <div class="item">
                        <label>所属企业</label>
                        <el-select v-model="searchForm.cp_id" style="width:150px">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option v-for="item in $store.state.CompanyDataList" :key="item.cp_id" :label="item.cp_name" :value="item.cp_id"></el-option>
                        </el-select>
                    </div>
                    <el-button type="primary" plain @click="Search" style="margin-left: 20px;">查询</el-button>
                </div>
            </el-card>




            <el-table :data='$store.state.EmpDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true' size="mini">
                <el-table-column align="center" prop="emp_name" label="姓名"></el-table-column>
                <el-table-column align="center" prop="cp_name" label="所属企业"></el-table-column>
                <el-table-column align="center" prop="emp_type" label="人员类型">
                    <template slot-scope="scope">
                       {{scope.row.emp_type===0?'执法人员':(scope.row.emp_type===1?'施工人员':(scope.row.emp_type===2?'安全员':(scope.row.emp_type===3?'资料员':(scope.row.emp_type===4?'仓管':'监理'))))}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="emp_phone" label="联系方式"></el-table-column>
                <el-table-column align="center" prop="emp_remark" label="备注"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                        <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="width:100%" layout="prev, pager, next" @current-change="current_change" :total="$store.state.EmpDataTotal" background :page-size="searchForm.pageSize"></el-pagination>




            <el-dialog v-if="empDialog" :title="title" :visible.sync="empDialog" width='40%' :append-to-body='true'>
                <el-form :model="empForm" :rules="rules" ref="empForm" label-width="100px" size='medium'>
                    <el-form-item label="姓名" prop="emp_name">
                         <el-input v-model="empForm.emp_name"></el-input>
                    </el-form-item>
                    <el-form-item label="所属企业" >
                        <el-select v-model="empForm.emp_company_id" style="width:100%;">
                            <el-option label="无" :value='0'></el-option>
                            <el-option v-for='item in $store.state.CompanyDataList' :key='item.cp_id' :label='item.cp_name' :value='item.cp_id'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人员类型">
                        <el-select v-model="empForm.emp_type" style="width:100%;">
                            <el-option label="执法人员" :value='0'></el-option>
                            <el-option label="施工人员" :value='1'></el-option>
                            <el-option label="安全员" :value='2'></el-option>
                            <el-option label="资料员" :value='3'></el-option>
                            <el-option label="仓管" :value='4'></el-option>
                            <el-option label="监理" :value='5'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系方式">
                        <el-input v-model="empForm.emp_phone"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="empForm.emp_remark"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="empDialog = false">取 消</el-button>
                    <el-button type="primary" @click="submit">确 定</el-button>
                </span>
            </el-dialog>
    
        </div>
    </div>
</template>

<script>
import {post,get} from '../../util/util'

export default{
    data(){
        return{
            searchForm:{
                pageNo:1,
                pageSize:15,
                emp_name:'',
                cp_id:0
            },

            empDialog:false,
            title:'',
            empForm:{
                emp_id:0,
                emp_name:'',
                emp_company_id:0,
                emp_type:0,
                emp_phone:'',
                emp_remark:''
            },
            rules:{
                emp_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
            }
        }
    },
    created(){
        if(this.$store.state.CompanyDataList.length===0){
            this.$store.dispatch('getCompanyDataList')
        }
    },
    mounted(){
        if(this.$store.state.EmpDataList.length===0){
            this.$store.dispatch('getEmpDataList',this.searchForm)
        }
    },
    methods:{
        Search(){
            this.$store.dispatch('getEmpDataList',this.searchForm)
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.Search()
        },
        Add(){
            this.empDialog=true
            this.title='新增'
            this.empForm.emp_company_id=0
            this.empForm.emp_id=0
            this.empForm.emp_name=''
            this.empForm.emp_phone=''
            this.empForm.emp_remark=''
        },
        Edit(row){
            this.empDialog=true
            this.title='修改'
            this.empForm=JSON.parse(JSON.stringify(row))
        },
        Del(row){
            this.$confirm('确定删除人员“' + row.emp_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                get('emp/del?id=' + row.emp_id+'&name='+row.emp_name).then(res => {
                    if (res.rpStatus === 10000) {
                        this.searchForm={
                            pageNo:1,
                            pageSize:15,
                            emp_name:'',
                            cp_id:0
                        }
                        this.$store.dispatch('getEmpDataList',this.searchForm)
                    } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            this.$refs.empForm.validate((valid) => {
                if (valid) {
                    post('emp/edit', this.empForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.searchForm={
                                pageNo:1,
                                pageSize:15,
                                emp_name:'',
                                cp_id:0
                            }
                            this.$store.dispatch('getEmpDataList',this.searchForm)
                            this.empDialog = false
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        }
    }
}


</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search{
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.item label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}   
</style>